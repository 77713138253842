/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 1.6.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/

// Icons

@import "custom/icons/unicons";
@import "custom/icons/remixicon";
@import "custom/icons/materialdesignicons";
